export default [
  {
    path: "/biblioteca",
    name: "libraryLayout",
    component: () =>
      import(
        /*webpackChunkName: "libraryLayout"*/ "../layouts/LibraryMainLayout.vue"
      ),
    children: [
      {
        path: "buscar",
        name: "librarySearch",
        component: () =>
          import(
            /*webpackChunkName: "librarySearch"*/ "../views/LibrarySearch.vue"
          ),
      },
      {
        path: "guardados",
        name: "libraryBookmarks",
        component: () =>
          import(
            /*webpackChunkName: "libraryBookmarks"*/ "../views/LibraryBookmarks.vue"
          ),
      },
    ],
  },
  {
    path: "/ver/tesis/:digitalId/:thesisName",
    name: "libraryResultWatch",
    component: () =>
      import(
        /*webpackChunkName: "libraryResultWatch"*/ "../views/ViewSearchResult.vue"
      ),
    params: true,
  },
];
