export default [
  {
    path: "/verid-ai",
    name: "veridAiLayout",
    component: () =>
      import(
        /*webpackChunkName: "veridAiLayout"*/ "../layouts/VeridAiLayout.vue"
      ),
    children: [
      {
        path: "analizar-documento",
        name: "analyzeDocIntro",
        component: () =>
          import(
            /*webpackChunkName: "analyzeDocIntro"*/ "../views/AnalyzeIntro.vue"
          ),
      },
    ],
  },
  {
    path: "/analizando-documento/:id",
    name: "analyzeDoc",
    component: () =>
      import(/*webpackChunkName: "analyzeDoc"*/ "../views/AnalyzeDocument.vue"),
    props: true,
  },
];
