import { defineStore } from "pinia";
import { get, put } from "aws-amplify/api";
import { uploadData, isCancelError, getUrl } from "aws-amplify/storage";
import { useUserStore } from "./userStore";
import { useAlertsToastify } from "./alertsToastify";

export const useLoadingContentStore = defineStore("loadingContent", {
  state: () => ({
    isLoading: false,
    messageLoading: "",
    isUploadingDownloading: false,
    currentOperation: null,
    totalWeightOfDocuments: 0,
    documentsToUpload: [],
    assocciatedFile: { name: "", id: "" },
    totalItemsToUpload: 0,
    numberItemUploading: 0,
    progressLoading: 0,

    arrayOfUploadedItems: [],

    isDownloading: false,
    isDownloadingZip: false,
    totalItemsToDownload: 0,
    numberItemDownloading: 0,
    progressDownloading: 0,

    isDeleting: false,
  }),

  actions: {
    setLoadingStatus(value) {
      this.isLoading = value;
    },
    setMessageLoading(value) {
      this.messageLoading = value;
    },
    setUploadingDownloadingStatus(value) {
      this.isUploadingDownloading = value;
    },
    setProgressLoading(value) {
      this.progressLoading = value;
    },
    setArrayOfUploadedItems(value) {
      this.arrayOfUploadedItems = [...this.arrayOfUploadedItems, value];
    },
    setIsDownloading(value) {
      this.isDownloading = value;
    },
    setIsDownloadingZip(value) {
      this.isDownloadingZip = value;
    },
    setIsDeleting(value) {
      this.isDeleting = value;
    },

    async uploadDocumentsToS3(folderSelected, documents, file) {
      const userStore = useUserStore();
      const alertsToastify = useAlertsToastify();

      if (this.isUploadingDownloading) {
        alertsToastify.showErrorToast("Ya hay una carga en proceso");
        return;
      }

      this.isUploadingDownloading = true;

      if (documents.length < 1) {
        this.isUploadingDownloading = false;
        alertsToastify.showErrorToast("No hay documentos para subir");
        return;
      }

      this.documentsToUpload = documents;
      this.assocciatedFile = file;

      this.totalWeightOfDocuments = documents.reduce(
        (acc, doc) => acc + doc.size,
        0
      );

      function normalizeFileName(fileName) {
        return fileName
          .normalize("NFD") // Normaliza para separar caracteres diacríticos
          .replace(/[\u0300-\u036f]/g, "") // Elimina marcas diacríticas
          .replace(/[^\w\s.-]/g, "") // Elimina caracteres no alfanuméricos excepto guion, punto y espacio
          .replace(/\s+/g, "_") // Reemplaza espacios por guiones bajos
          .replace(/_{2,}/g, "_") // Reemplaza guiones bajos repetidos por uno solo
          .replace(/^\./, ""); // Evita que el nombre comience con un punto (problema común en UNIX)
      }

      try {
        const preValidateFiles = get({
          apiName: "CarryAppWeb",
          path: "/api/docs/prevalidatespace",
          options: {
            queryParams: {
              weightLoad: this.totalWeightOfDocuments,
            },
          },
        });

        const { body } = await preValidateFiles.response;
        const response = await body.json();

        if (!response.success) {
          throw new Error(response.message);
        }

        const userId = await userStore.getUserId;
        const userEmail = await userStore.getUserEmail;

        this.totalItemsToUpload = documents.length;

        for (const doc of documents) {
          this.numberItemUploading++;

          let fileNameNormalized = normalizeFileName(doc.name);
          let nameDoc = `${Date.now()}-${fileNameNormalized}`;

          // ! DONT DELETE METADATA, WORKS TO VERIFY USER AND IF IS NOT VALID DELETE EACH FILE UPLOADED
          this.currentOperation = uploadData({
            path: ({ identityId }) => `protected/${identityId}/${nameDoc}`,
            data: doc,
            options: {
              onProgress: ({ transferredBytes, totalBytes }) => {
                if (totalBytes) {
                  this.progressLoading = Math.round(
                    (transferredBytes / totalBytes) * 100
                  );
                }
              },
              metadata: {
                userId: userId,
                email: userEmail,
                name: fileNameNormalized,
                folder: folderSelected,
                associatedFileId: this.assocciatedFile.id,
              },
            },
          });

          const uploadResult = await this.currentOperation.result;

          this.arrayOfUploadedItems.unshift({
            uploadResult,
          });

          this.progressLoading = 0;
          this.currentOperation = null;
        }

        alertsToastify.showSuccessToast("Documento(s) subidos correctamente");
      } catch (error) {
        if (isCancelError(error)) {
          alertsToastify.showInfoToast("Carga cancelada por usuario");
        } else if (error.message.includes("Sin espacio disponible")) {
          alertsToastify.showErrorToast(
            "Ya no tienes espacio disponible, sube de plan o compra espacio adicional en ajustes."
          );
        } else if (
          error.message.includes(
            "The request signature we calculated does not match the signature you provided. Check your key and signing method."
          )
        ) {
          alertsToastify.showErrorToast(
            "Documento corrupto, intente subir otro archivo"
          );
        } else {
          alertsToastify.showErrorToast(error.message);
        }
      } finally {
        this.isUploadingDownloading = false;
        this.currentOperation = null;
        this.documentsToUpload = [];
        this.assocciatedFile = { name: "", id: "" };
        this.totalItemsToUpload = 0;
        this.numberItemUploading = 0;
        this.progressLoading = 0;
        this.totalWeightOfDocuments = 0;
        this.arrayOfUploadedItems = [];
      }
    },

    async cancelUpload() {
      const alertsToastify = useAlertsToastify();

      if (this.currentOperation) {
        this.currentOperation.cancel();
      } else {
        alertsToastify.showWarningToast(
          "No hay cargas en proceso para cancelar"
        );
      }
    },

    async downloadDocumentsFromS3(documents) {
      if (this.isDownloading) {
        alertsToastify.showErrorToast("Ya hay una descarga en proceso");
        return;
      }

      if (documents.length < 1) {
        alertsToastify.showErrorToast("No hay documentos para descargar");
        return;
      }

      const alertsToastify = useAlertsToastify();

      alertsToastify.showInfoToast("Comenzando descarga de archivos");
      this.setIsDownloading(true);
      this.totalItemsToDownload = documents.length;

      const documentsToDownload = documents.filter(
        (doc) => doc.itemType === "doc"
      );

      const foldersToDownload = documents
        .filter((doc) => doc.itemType === "folder")
        .map((folder) => ({ id: folder.id }));

      try {
        for (const doc of documentsToDownload) {
          this.numberItemDownloading++;

          const operationDownload = await getUrl({
            path: doc.fileKeyS3,
            options: {
              expiresIn: 300,
              onProgress: (progress) => {
                this.progressDownloading =
                  (progress.transferredBytes / progress.totalBytes) * 100;
              },
            },
          });

          let urlToDownload = operationDownload.url.href;

          const a = document.createElement("a");
          a.href = urlToDownload;
          a.download = doc.name;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          this.progressDownloading = 0;
        }

        if (foldersToDownload.length > 0) {
          this.setIsDownloadingZip(true);

          const operationDownloadFolders = put({
            apiName: "CarryAppWeb",
            path: "/api/docs/downloadfoldersaszip",
            options: {
              body: {
                folders: foldersToDownload,
              },
            },
          });

          const { body } = await operationDownloadFolders.response;
          const response = await body.json();

          if (!response.success) {
            throw new Error(response.message);
          }

          const linksToClick = response.urls;

          if (linksToClick.length > 0) {
            linksToClick.forEach((link, index) => {
              setTimeout(() => {
                const a = document.createElement("a");
                a.href = link;
                // a.target = "_blank";
                a.download = `carpeta-${Date.now()}.zip`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                if (index === linksToClick.length - 1) {
                  this.setIsDownloadingZip(false);
                }
              }, index * 2000); // 2000 ms (2 segundos) de retraso entre cada descarga para evitar bloqueo de descargas
            });
          }
        }

        alertsToastify.showSuccessToast("Descarga de archivos completada");
      } catch (error) {
        alertsToastify.showErrorToast(error.message);
      } finally {
        this.setIsDownloading(false);
        this.totalItemsToDownload = 0;
        this.numberItemDownloading = 0;
        this.progressDownloading = 0;
      }
    },

    async deleteDocumentsFromS3(documents) {
      if (documents.length < 1) {
        alertsToastify.showErrorToast("No hay documentos para eliminar");
        return;
      }

      const alertsToastify = useAlertsToastify();
      alertsToastify.showInfoToast("Comenzando eliminación de archivos");

      this.setIsDeleting(true);

      try {
        const operationDeleteDocuments = put({
          apiName: "CarryAppWeb",
          path: "/api/docs/deletedocumentsandfolders",
          options: {
            body: {
              items: documents,
            },
          },
        });

        await operationDeleteDocuments.response;

        alertsToastify.showSuccessToast("Documento(s) eliminado(s)");
      } catch (error) {
        alertsToastify.showErrorToast(error.message);
      } finally {
        this.setIsDeleting(false);
      }
    },
  },
});
