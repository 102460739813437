export default [
  {
    path: "/cobros",
    children: [
      {
        path: "",
        name: "payments",
        component: () =>
          import(
            /*webpackChunkName: "paymentsMain"*/ "../views/PaymentsMain.vue"
          ),
      },
      {
        path: "ver/:status/:id",
        name: "viewPayment",
        component: () =>
          import(
            /*webpackChunkName: "viewPayment"*/ "../views/ViewPayment.vue"
          ),
      },
    ],
  },
];
