import { defineStore } from "pinia";
import { fetchUserAttributes } from "aws-amplify/auth";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    userName: null,
    userLastName: null,
    userTimeZone: null,
    userEmail: null,
    userId: null,
    userProfilePicUrl: null,
  }),
  getters: {
    getUserName(state) {
      return state.userName;
    },
    getUserLastName(state) {
      return state.userLastName;
    },
    //! No lo modifiques, fallaría envíar timezone a la API en vista Mis tareas
    async getUserTimeZone(state) {
      if (state.userTimeZone === null) {
        await this.loadUserAttributes();
      }
      return state.userTimeZone;
    },
    // ! No lo modifiques, potencial falla en loadingContent UploadDocumentsToS3 o docs cobros create and update
    getUserEmail(state) {
      if (state.userEmail === null) {
        this.loadUserAttributes();
      }
      return state.userEmail;
    },
    // ! No lo modifiques, potencial falla en loadingContent UploadDocumentsToS3 o docs cobros create and update
    async getUserId(state) {
      if (state.userId === null) {
        await this.loadUserAttributes();
      }
      return state.userId;
    },
    getUserProfilePicUrl(state) {
      return state.userProfilePicUrl;
    },
  },
  actions: {
    async loadUserAttributes() {
      if (
        !this.userName ||
        !this.userLastName ||
        !this.userEmail ||
        !this.userTimeZone ||
        !this.userId
      ) {
        try {
          const userAttributes = await fetchUserAttributes();
          if (userAttributes) {
            this.setUserName(userAttributes.name);
            this.setUserLastName(userAttributes.family_name);
            this.setUserEmail(userAttributes.email);
            this.setUserTimeZone(userAttributes["custom:timeZone"]);
            this.setUserId(userAttributes.sub);
          }
        } catch (error) {
          console.error("Error fetching user attributes: ", error);
        }
      }
    },
    setUserName(value) {
      this.userName = value;
    },
    setUserLastName(value) {
      this.userLastName = value;
    },
    setUserEmail(value) {
      this.userEmail = value;
    },
    setUserTimeZone(value) {
      this.userTimeZone = value;
    },
    setUserId(value) {
      this.userId = value;
    },
    setUserProfilePicUrl(value) {
      this.userProfilePicUrl = value;
    },
  },
});
