export default [
  {
    path: "/docs",
    name: "docsMainView",
    component: () =>
      import(/*webpackChunkName: "docsMainView"*/ "../views/DocsMainView.vue"),
    children: [
      {
        path: "todos",
        name: "allDocsMainView",
        component: () =>
          import(
            /*webpackChunkName: "allDocsMainView"*/ "../views/AllDocsAndFoldersView.vue"
          ),
      },
      {
        path: "folder/:id/:name",
        name: "FolderViewDocuments",
        component: () =>
          import(
            /*webpackChunkName: "FolderViewDocuments"*/ "../views/AllDocsAndFoldersView.vue"
          ),
        params: true,
      },
    ],
  },
];
