export default [
  {
    path: "/firmas",
    children: [
      {
        path: "",
        name: "Signs",
        component: () =>
          import(/*webpackChunkName: "signsMain"*/ "../views/SignsMain.vue"),
      },
      {
        path: "ver/:id",
        name: "viewSign",
        component: () =>
          import(/*webpackChunkName: "viewSign"*/ "../views/ViewSign.vue"),
      },
      {
        path: "crear",
        name: "createSign",
        component: () =>
          import(/*webpackChunkName: "createSign"*/ "../modals/CreateSign.vue"),
      },
    ],
  },
];
