import { put } from "aws-amplify/api";
import { ref, onMounted, onBeforeUnmount } from "vue";

export default function useActivityTracker() {
  const isTracking = ref(false); // Estado de seguimiento
  const timerId = ref(null); // Referencia al temporizador activo
  const LOCAL_STORAGE_KEY = "lastActivitySentAt"; // Llave para almacenar la última marca de tiempo

  // Función para obtener los datos de actividad del usuario
  const getUserActivityData = () => {
    const now = new Date();
    const hours = now.getHours();

    // Rango de horas basado en la hora actual
    const hourRanges = [
      { name: "earlyMorning", start: 2, end: 6 },
      { name: "morning", start: 6, end: 10 },
      { name: "lateMorning", start: 10, end: 14 },
      { name: "afternoon", start: 14, end: 18 },
      { name: "evening", start: 18, end: 22 },
      { name: "lateEvening", start: 22, end: 2 },
    ];
    const currentHourRange = hourRanges.find((range) => {
      if (range.start < range.end) {
        // Caso normal: rango no cruza la medianoche
        return hours >= range.start && hours < range.end;
      } else {
        // Caso especial: rango cruza la medianoche
        return hours >= range.start || hours < range.end;
      }
    })?.name;

    // Día de la semana
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const currentDay = days[now.getDay()];

    // Dispositivo actual
    const currentDevice = /Tablet|iPad/i.test(navigator.userAgent)
      ? "tablet"
      : /Mobi|Android/i.test(navigator.userAgent)
      ? "mobile"
      : "desktop";

    return {
      hour: currentHourRange,
      day: currentDay,
      device: currentDevice,
    };
  };

  // Función para enviar los datos de actividad al servidor
  const sendActivity = async () => {
    const activityData = getUserActivityData();

    try {
      const operationPutActivity = put({
        apiName: "CarryAppWeb",
        path: "/api/settings/registeractivity",
        options: {
          body: {
            dataActivity: activityData,
          },
        },
      });

      await operationPutActivity.response;

      const now = new Date().getTime();
      localStorage.setItem(LOCAL_STORAGE_KEY, now.toString());

      scheduleNextSend();
    } catch (error) {
      console.error("Error enviando actividad:", error);
    }
  };

  // Función para programar el siguiente envío dinámicamente
  const scheduleNextSend = () => {
    // Limpia cualquier temporizador existente
    if (timerId.value) clearTimeout(timerId.value);

    const lastActivitySentAt = localStorage.getItem(LOCAL_STORAGE_KEY);
    const now = new Date().getTime();

    // Si nunca se ha enviado, programa el envío inmediatamente
    if (!lastActivitySentAt) {
      sendActivity();
      return;
    }

    const timeSinceLastSend = now - parseInt(lastActivitySentAt, 10);
    const timeUntilNextSend = 30 * 60 * 1000 - timeSinceLastSend;

    // Si ya pasaron los 30 minutos, envía de inmediato
    if (timeUntilNextSend <= 0) {
      sendActivity();
    } else {
      timerId.value = setTimeout(sendActivity, timeUntilNextSend);
    }
  };

  // Inicia el seguimiento
  const startTracking = () => {
    if (isTracking.value) return;
    isTracking.value = true;

    scheduleNextSend(); // Programa el primer envío
  };

  // Limpia el temporizador al desmontar el componente
  onBeforeUnmount(() => {
    if (timerId.value) clearTimeout(timerId.value);
    isTracking.value = false;
  });

  onMounted(() => {
    startTracking(); // Inicia el seguimiento al montar el componente
  });

  return {
    isTracking,
  };
}
