export default [
  {
    path: "/calculadora/:id",
    name: "useCalculator",
    component: () =>
      import(
        /*webpackChunkName: "useCalculator"*/ "../views/UseCalculator.vue"
      ),
    props: true,
  },
];
