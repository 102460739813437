import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export const useAlertsToastify = defineStore("alertsToastify", {
  state: () => ({
    toastOptions: {
      position: "top-center",
      timeout: 3000,
      autoClose: 3000,
      transition: "slide",
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      icon: true,
      rtl: false,
      style: {
        zIndex: 9999, // Aquí defines el z-index
      },
    },
    toastInfoOptions: {
      position: "top-center",
      timeout: 3000,
      autoClose: 600,
      transition: "slide",
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      icon: true,
      rtl: false,
      style: {
        zIndex: 9999, // Aquí defines el z-index
      },
    },
  }),
  actions: {
    showSuccessToast(message) {
      toast.success(message, this.toastOptions);
    },
    showErrorToast(message) {
      toast.error(message, this.toastOptions);
    },
    showInfoToast(message) {
      toast.info(message, this.toastInfoOptions);
    },
    showWarningToast(message) {
      toast.warning(message, this.toastOptions);
    },
  },
});
