export default [
  {
    path: "/tareas",
    name: "tasks",
    component: () =>
      import(/* webpackChunkName: "tasksMain" */ "../Layouts/TasksMain.vue"),
    children: [
      {
        path: "mis-tareas",
        name: "myTasks",
        component: () =>
          import(/* webpackChunkName: "myTasks" */ "../views/MyTasks.vue"),
      },
      {
        path: "asignadas",
        name: "tasksAssigned",
        component: () =>
          import(
            /* webpackChunkName: "assignedTasks" */ "../views/AssignedTasks.vue"
          ),
      },
      {
        path: "historial",
        name: "tasksHistory",
        component: () =>
          import(
            /* webpackChunkName: "tasksHistory" */ "../views/TasksHistory.vue"
          ),
      },
    ],
  },
];
