import { useActiveViewStore } from "../../stores/viewActive";

export default function useBack(router) {
  const store = useActiveViewStore();

  router.afterEach((to) => {
    if (store.getCancelledTo) {
      store.setCancelledTo(false);
      return;
    }

    const noBackRoutes = [
      "/",
      "/inicio",
      "/firmas",
      "/docs",
      "/docs/todos",
      "/cobros",
      "/contactos",
      "/calendario",
      "/expedientes",
      "/tareas/mis-tareas",
      "/tareas/asignadas",
      "/tareas/historial",
      "/plantillas",
      "/plantillas/todas",
      "/plantillas/carpeta",
      "/metricas",
      "/verid-ai",
      "/verid-ai/analizar-documento",
      "/biblioteca/buscar",
      "/biblioteca/guardados",
    ];
    const isBackNecessary = !noBackRoutes.includes(to.path);
    store.setBackNecessity(isBackNecessary);
  });
}
