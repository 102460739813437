import { defineStore } from "pinia";

export const usePlanLimitReachedStore = defineStore("planLimitReached", {
  state: () => ({
    isPlanLimitReachedModalOpen: false,
    headerPlanLimitReachedModal: "¡Eso es productividad!",
    firstTextPlanLimitReachedModal: "Llevaste al máximo el plan gratuito.",
    secondTextPlanLimitReachedModal: "Sube de nivel y ejerce sin límites.",
  }),

  getters: {
    getPlanLimitReachedModalOpen: (state) => state.isPlanLimitReachedModalOpen,
    getHeaderPlanLimitReachedModal: (state) =>
      state.headerPlanLimitReachedModal,
    getfirstTextPlanLimitReachedModal: (state) =>
      state.firstTextPlanLimitReachedModal,
    getSecondTextPlanLimitReachedModal: (state) =>
      state.secondTextPlanLimitReachedModal,
  },

  actions: {
    openPlanLimitReachedModal(header, firstText, secondText) {
      if (header) this.headerPlanLimitReachedModal = header;
      if (firstText) this.firstTextPlanLimitReachedModal = firstText;
      if (secondText) this.secondMssg = secondText;

      this.isPlanLimitReachedModalOpen = !this.isPlanLimitReachedModalOpen;
    },
    closePlanLimitReachedModal() {
      this.isPlanLimitReachedModalOpen = false;
      this.headerPlanLimitReachedModal = "¡Eso es productividad!";
      this.firstTextPlanLimitReachedModal =
        "Llevaste al máximo el plan gratuito de este mes.";
      this.secondTextPlanLimitReachedModal =
        "Sube de nivel y ejerce sin límites.";
    },
  },
});
