import { defineStore } from "pinia";
import { get, put } from "aws-amplify/api";
import { useAlertsToastify } from "./alertsToastify";

export const useNotificationsStore = defineStore("notifications", {
  state: () => ({
    notifications: [],
    hasMoreNotifications: true,
    isNotificationsOpen: false,
    hasUnreadenNotifications: false,
    isLoadingNotifications: false,
    currentPage: 1,
    totalPages: 1,
  }),

  getters: {
    getNotifications() {
      return this.notifications;
    },
    getUnreadNotifications() {
      return this.notifications.filter(
        (notification) => notification.isRead === 0
      );
    },
    getIsNotificationsOpen() {
      return this.isNotificationsOpen;
    },
    getHasUnreadenNotifications() {
      return this.notifications.some((notification) => !notification.isRead);
    },
  },

  actions: {
    toggleNotifications() {
      this.isNotificationsOpen = !this.isNotificationsOpen;

      if (this.isNotificationsOpen) {
        this.markAsRead();
      } else {
        // Update local only when the notifications are closed
        this.notifications.forEach((notification) => {
          if (notification.isRead === 0) {
            notification.isRead = 1;
          }
        });
      }
    },
    async markAsRead() {
      const alertsToastify = useAlertsToastify();

      // Get notifications that are unread
      const arrayOfNotifications = this.notifications.filter(
        (notification) => notification.isRead === 0
      );

      // Get the ids of the notifications
      const ids = arrayOfNotifications.map((notification) => notification.id);

      if (ids.length < 1) {
        return;
      }

      try {
        const operationMarkAsReadNotifications = put({
          apiName: "CarryAppWeb",
          path: "/api/settings/markasreadnotifications",
          options: {
            body: {
              notifications: ids,
            },
          },
        });

        // Update the notifications in backend
        await operationMarkAsReadNotifications.response;
      } catch (error) {
        alertsToastify.showErrorToast(error.message);
      }
    },
    async fetchNotifications(isLoadingMore = false) {
      if (this.isLoadingNotifications || this.currentPage > this.totalPages)
        return;

      if (!isLoadingMore && this.notifications.length > 0) {
        return;
      }

      const alertsToastify = useAlertsToastify();

      this.isLoadingNotifications = true;

      try {
        const operationFetchNotifications = get({
          apiName: "CarryAppWeb",
          path: "/api/settings/getnotifications",
          options: {
            queryParams: {
              page: this.currentPage,
            },
          },
        });

        const { body } = await operationFetchNotifications.response;
        const response = await body.json();

        this.notifications.push(...response.data);
        this.totalPages = response.totalPages;
        this.currentPage++;

        if (this.currentPage > this.totalPages) {
          this.hasMoreNotifications = false;
        }

        if (this.isNotificationsOpen) {
          this.markAsRead();
        }
      } catch (error) {
        alertsToastify.showErrorToast(error.message);
      } finally {
        this.isLoadingNotifications = false;
      }
    },
  },
});
