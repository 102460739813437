import { createRouter, createWebHistory } from "vue-router";
import signsRoutes from "@/modules/Signs/router/signsRoutes";
import veridAiRoutes from "@/modules/VeridAI/routes/veridAiRoutes";
import filesRoutes from "@/modules/Files/router/filesRoutes";
import docsRoutes from "@/modules/Docs/router/docsRoutes";
import paymentsRoutes from "@/modules/Payments/router/paymentsRoutes";
import contactsRoutes from "@/modules/Contacts/router/contactsRoutes";
import calendarRoutes from "@/modules/Calendar/router/calendarRoutes";
import tasksRoutes from "@/modules/Tasks/router/tasksRoutes";
import metricsRoutes from "@/modules/Metrics/routes/metricsRoutes";
import settingsRoutes from "@/modules/Settings/routes/settingsRoutes";
import calculatorsRoutes from "@/modules/Calculators/routes/calculatorsRoutes";
import libraryRoutes from "@/modules/Library/routes/libraryRoutes";

const routes = [
  {
    path: "/",
    redirect: "/inicio",
  },
  {
    path: "/inicio",
    name: "Home",
    component: () =>
      import(/*webpackChunkName: "home"*/ "../modules/Home/views/HomePage.vue"),
  },
  ...signsRoutes,
  ...filesRoutes,
  ...docsRoutes,
  ...paymentsRoutes,
  ...contactsRoutes,
  ...calendarRoutes,
  ...tasksRoutes,
  ...metricsRoutes,
  ...veridAiRoutes,
  ...settingsRoutes,
  ...calculatorsRoutes,
  ...libraryRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Mantiene la posición anterior (si es relevante)
    } else {
      return { top: 0 }; // Siempre desplaza hacia arriba
    }
  },
});

export default router;
