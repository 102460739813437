import { computed, ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import { useAlertsToastify } from "@/stores/alertsToastify";

const useMenu = () => {
  const userStore = useUserStore();
  const alertsToastify = useAlertsToastify();
  const isMenuVisible = ref(false);
  const isSearchActive = ref(false);
  const userName = computed(() => userStore.getUserName);
  const isSupSearchActive = ref(false);

  const toggleSupSearch = () => {
    isSupSearchActive.value = !isSupSearchActive.value;
  };

  const toggleMenu = () => {
    isMenuVisible.value = !isMenuVisible.value;
  };

  const closeMenu = () => {
    isMenuVisible.value = false;
  };

  const toggleSearch = () => {
    if (window.innerWidth < 768) {
      isSearchActive.value = !isSearchActive.value;
    }
  };

  const svgToggleSearch = () => {
    isSearchActive.value ? () => {} : toggleSearch();
  };

  const profilePicUrl = computed(() => userStore.getUserProfilePicUrl);

  const retrieveUserProfilePic = async () => {
    try {
      // TODO Implementar llamada a API para obtener la imagen de perfil

      userStore.setUserProfilePicUrl(
        "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/B-21_Raider_front_high.jpg/300px-B-21_Raider_front_high.jpg"
      );
      userStore.setUserProfilePicUrl(null);
    } catch (error) {
      alertsToastify.showErrorToast("Error al cargar la imagen de perfil");
    }
  };

  return {
    retrieveUserProfilePic,
    profilePicUrl,
    userName,
    isMenuVisible,
    toggleMenu,
    closeMenu,
    isSearchActive,
    toggleSearch,
    svgToggleSearch,
    isSupSearchActive,
    toggleSupSearch,
  };
};

export default useMenu;
