<template>
  <section class="global_container">
    <div class="left_side">
      <computer-menu class="global_computer_menu"></computer-menu>
    </div>
    <div class="right_side">
      <div class="global_header">
        <computer-sup-menu class="computer_sup_menu"></computer-sup-menu>
        <mobile-menu
          class="global_mobile_menu"
          @toggleMenu="toggleMenu"
        ></mobile-menu>
        <div class="notifications" v-if="isNotificationsOpen">
          <notifications-modal></notifications-modal>
        </div>
      </div>
      <div class="global_content">
        <router-view class="router"></router-view>
      </div>
      <deployable-menu
        v-show="isMenuVisible"
        @closeMenu="closeMenu"
        :isMenuVisible="isMenuVisible"
      ></deployable-menu>
    </div>
    <loading-modal v-if="isLoadingContent"></loading-modal>
    <plan-limit-reached-modal
      v-if="isPlanLimitReachedModalOpen"
      class="modal"
    ></plan-limit-reached-modal>
    <expiration-token-modal
      v-if="isModalExpirationTokenVisible"
      class="modal"
    ></expiration-token-modal>
    <uploading-downloading-loader-progress
      v-if="isUploadingOrDownloading"
    ></uploading-downloading-loader-progress>
    <download-loader-progress
      v-if="isDownloadingActive"
    ></download-loader-progress>
    <deleting-modal-loader v-if="isDeletingActive"></deleting-modal-loader>
  </section>
</template>

<script>
import { defineAsyncComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { useNotificationsStore } from "@/stores/notifications";
import { useLoadingContentStore } from "@/stores/loadingContent";
import { usePlanLimitReachedStore } from "@/stores/planLimitReached";
import { useAuthStore } from "@/stores/storeAuth";
import useMenu from "@/helpers/methods/useMenu";

export default {
  components: {
    ComputerSupMenu: defineAsyncComponent(() =>
      import("@/components/ComputerSupMenu.vue")
    ),
    MobileMenu: defineAsyncComponent(() =>
      import("@/components/MobileMenu.vue")
    ),
    ComputerMenu: defineAsyncComponent(() =>
      import("@/components/ComputerMenu.vue")
    ),
    DeployableMenu: defineAsyncComponent(() =>
      import("@/components/DeployableMenu.vue")
    ),
    NotificationsModal: defineAsyncComponent(() =>
      import("@/modules/Notifications/modals/NotificationsModal.vue")
    ),
    LoadingModal: defineAsyncComponent(() =>
      import("@/modals/LoadingModal.vue")
    ),
    PlanLimitReachedModal: defineAsyncComponent(() =>
      import("@/modals/PlanLimitReachedModal.vue")
    ),
    ExpirationTokenModal: defineAsyncComponent(() =>
      import("@/modals/ExpirationTokenModal.vue")
    ),
    UploadingDownloadingLoaderProgress: defineAsyncComponent(() =>
      import("@/modules/Docs/modals/UploadingDownloadingLoaderProgress.vue")
    ),
    DownloadLoaderProgress: defineAsyncComponent(() =>
      import("@/modules/Docs/modals/DownloadLoaderProgress.vue")
    ),
    DeletingModalLoader: defineAsyncComponent(() =>
      import("@/modules/Docs/modals/DeletingModalLoader.vue")
    ),
  },

  setup() {
    const router = useRouter();
    const { isMenuVisible, toggleMenu, closeMenu } = useMenu();
    const notificationsStore = useNotificationsStore();
    const isNotificationsOpen = computed(
      () => notificationsStore.getIsNotificationsOpen
    );

    const LoadingStore = useLoadingContentStore();
    const isLoadingContent = computed(() => LoadingStore.isLoading);
    const isUploadingOrDownloading = computed(
      () => LoadingStore.isUploadingDownloading
    );
    const isDownloadingActive = computed(() => LoadingStore.isDownloading);
    const isDeletingActive = computed(() => LoadingStore.isDeleting);

    const planLimitReachedStore = usePlanLimitReachedStore();
    const isPlanLimitReachedModalOpen = computed(
      () => planLimitReachedStore.getPlanLimitReachedModalOpen
    );

    const authStore = useAuthStore();
    const isModalExpirationTokenVisible = computed(
      () => authStore.isModalExpirationTokenVisible
    );

    router.afterEach(() => {
      closeMenu();
    });

    return {
      isMenuVisible,
      toggleMenu,
      closeMenu,
      isNotificationsOpen,
      isLoadingContent,
      isPlanLimitReachedModalOpen,
      isModalExpirationTokenVisible,
      isUploadingOrDownloading,
      isDownloadingActive,
      isDeletingActive,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/main_layout.scss";
</style>
