<template>
  <main>
    <main-layout></main-layout>
  </main>
</template>

<script>
import { onMounted, onBeforeMount } from "vue";
import { useUserStore } from "./stores/userStore";
import { useNotificationsStore } from "@/stores/notifications";
import { useAuthStore } from "@/stores/storeAuth";
import MainLayout from "./layouts/MainLayout.vue";
import useActivityTracking from "@/helpers/methods/useActivityTracking";

export default {
  components: { MainLayout },

  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const notificationsStore = useNotificationsStore();
    const { isTracking } = useActivityTracking();

    onBeforeMount(() => {
      authStore.fetchUser();
    });

    onMounted(async () => {
      await userStore.loadUserAttributes();
      notificationsStore.fetchNotifications();
    });

    return {
      isTracking,
    };
  },
};
</script>

<style></style>
