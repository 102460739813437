export default [
  {
    name: "settingsLayout",
    path: "/ajustes",
    component: () =>
      import(
        /*webpackChunkName: "settingsMain"*/ "../layouts/SettingsLayout.vue"
      ),
    children: [
      {
        name: "accountSettings",
        path: "cuenta",
        component: () =>
          import(
            /*webpackChunkName: "accountSettings"*/ "../views/AccountSettings.vue"
          ),
      },
      {
        name: "billingSettings",
        path: "facturacion",
        component: () =>
          import(
            /*webpackChunkName: "billingSettings"*/ "../views/BillingSettings.vue"
          ),
      },
      {
        name: "securitySettings",
        path: "seguridad",
        component: () =>
          import(
            /*webpackChunkName: "securitySettings"*/ "../views/SecuritySettings.vue"
          ),
      },
      {
        name: "alertSettings",
        path: "notificaciones",
        component: () =>
          import(
            /*webpackChunkName: "alertSettings"*/ "../views/AlertSettings.vue"
          ),
      },
      {
        name: "connectionSettings",
        path: "cuentas-conectadas",
        component: () =>
          import(
            /*webpackChunkName: "connectionSettings"*/ "../views/ConnectionSettings.vue"
          ),
      },
      {
        name: "store",
        path: "tienda",
        component: () =>
          import(/*webpackChunkName: "store"*/ "../views/StoreSettings.vue"),
      },
      {
        name: "zoomIntegration",
        path: "cuentas-conectadas/zoom",
        component: () =>
          import(
            /*webpackChunkName: "connectionSettings"*/ "../views/ConnectionSettings.vue"
          ),
      },
    ],
  },
];
