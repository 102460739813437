import { defineStore } from "pinia";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    loading: false,
    isModalExpirationTokenVisible: false,
    sessionId: null,
    typeDevice: null,
    durationInterval: null,
  }),
  actions: {
    async fetchUser() {
      this.loading = true;
      try {
        const user = await getCurrentUser();
        this.user = user;
      } catch (error) {
        if (error.message.includes("User needs to be authenticated")) {
          this.closePageBecauseUnauthenticated();
        }
      } finally {
        this.loading = false;
      }
    },

    async logout() {
      const cookieStorage = new CookieStorage({
        // domain: ".carry.test",
        // secure: false,
        // path: "/",
        // sameSite: "lax",
        // expires: 160,
        domain: ".carry.legal",
        secure: true,
        path: "/",
        sameSite: "lax",
        expires: 160,
      });

      cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);

      try {
        await signOut();
        await this.endSession();
        this.isModalExpirationTokenVisible = true;
        setTimeout(() => {
          window.location.href = "https://carry.legal/iniciar-sesion/";
        }, 20000);
      } catch (error) {
        console.log(error);
      }
    },

    closePageBecauseUnauthenticated() {
      window.location.href = "https://carry.legal/iniciar-sesion/";
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
  },
});
