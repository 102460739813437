export default [
  {
    path: "/calendario",
    children: [
      {
        path: "",
        name: "calendar",
        component: () =>
          import(
            /*webpackChunkName: "calendarMain"*/ "../views/CalendarMain.vue"
          ),
      },
    ],
  },
];
