export default [
  {
    path: "/contactos",
    children: [
      {
        path: "",
        name: "Contacts",
        component: () =>
          import(
            /*webpackChunkName: "contactsMain"*/ "../views/ContactsMain.vue"
          ),
      },
      {
        path: "ver/:id",
        name: "ViewContact",
        component: () =>
          import(
            /*webpackChunkName: "viewContact"*/ "../layouts/ViewContactLayout.vue"
          ),
        props: true,
        children: [
          {
            path: "informacion",
            name: "ContactInformation",
            component: () =>
              import(
                /*webpackChunkName: "viewContactInfo"*/ "../views/InformationSection.vue"
              ),
            props: true,
          },
          {
            path: "expedientes",
            name: "ContactFiles",
            component: () =>
              import(
                /*webpackChunkName: "viewContactFiles"*/ "../views/FilesSection.vue"
              ),
            props: true,
          },
          {
            path: "cobros",
            name: "ContactPayments",
            component: () =>
              import(
                /*webpackChunkName: "viewContactPayments"*/ "../views/PaymentsSection.vue"
              ),
            props: true,
          },
          {
            path: "eventos",
            name: "ContactEvents",
            component: () =>
              import(
                /*webpackChunkName: "viewContactEvents"*/ "../views/EventsSection.vue"
              ),
            props: true,
          },
          // {
          //   path: "emails",
          //   name: "ContactEmails",
          //   component: () =>
          //     import(
          //       /*webpackChunkName: "viewContactEmails"*/ "../views/EmailsSection.vue"
          //     ),
          // },
          // {
          //   path: "email/:id",
          //   name: "ViewEmail",
          //   component: () =>
          //     import(
          //       /*webpackChunkName: "viewContactEmail"*/ "../views/ViewEmail.vue"
          //     ),
          // },
          // {
          //   path: "otros",
          //   name: "ContactOthers",
          //   component: () =>
          //     import(
          //       /*webpackChunkName: "viewContactOthers"*/ "../views/OthersSection.vue"
          //     ),
          // },
        ],
      },
    ],
  },
];
