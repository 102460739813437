import { defineStore } from "pinia";

export const useActiveViewStore = defineStore("activeView", {
  state: () => ({
    title: "Bienvenido, ",
    secWord: "Adrián",
    isBackNecessary: false,
    isCancelledTo: false,
  }),

  getters: {
    getTitle() {
      return this.title;
    },
    getSecWord() {
      return this.user;
    },
    getBackNecessity() {
      return this.isBackNecessary;
    },
    getCancelledTo() {
      return this.isCancelledTo;
    },
  },

  actions: {
    setTitle(title) {
      this.title = title;
    },
    setSecWord(user) {
      this.user = user;
    },
    setBackNecessity(isNecessary) {
      this.isBackNecessary = isNecessary;
    },
    setCancelledTo(isCancelled) {
      //Used for beforeRouteLeave validation in useBack.js
      this.isCancelledTo = isCancelled;
    },
  },
});
