export default [
  {
    path: "/expedientes",
    children: [
      {
        path: "",
        name: "Files",
        component: () =>
          import(/*webpackChunkName: "filesMain"*/ "../views/FilesMain.vue"),
      },
      {
        path: "ver/:fileId/:fileName",
        name: "ViewFile",
        component: () =>
          import(
            /*webpackChunkName: "viewFile"*/ "../layouts/ViewFileLayout.vue"
          ),
        props: true,
        children: [
          {
            path: "info",
            name: "InfoSection",
            component: () =>
              import(
                /*webpackChunkName: "viewInfoFile"*/ "../views/InformationSection.vue"
              ),
          },
          {
            path: "docs",
            name: "DocsSection",
            component: () =>
              import(
                /*webpackChunkName: "viewDocsFile"*/ "../views/DocumentsSection.vue"
              ),
            children: [
              {
                path: "todos",
                name: "allDocsOfFile",
                component: () =>
                  import(
                    /*webpackChunkName: "allDocsOfFile"*/ "../views/AllDocsOfFile.vue"
                  ),
              },
              {
                path: "folder/:id/:name",
                name: "FolderOfDocInFile",
                component: () =>
                  import(
                    /*webpackChunkName: "FolderOfDocInFile"*/ "../views/AllDocsOfFile.vue"
                  ),
                params: true,
              },
            ],
          },
          {
            path: "finanzas",
            name: "FinancesSection",
            component: () =>
              import(
                /*webpackChunkName: "viewPaymentsFile"*/ "../views/PaymentsSection.vue"
              ),
          },
          {
            path: "eventos",
            name: "EventsSection",
            component: () =>
              import(
                /*webpackChunkName: "viewEventsFile"*/ "../views/EventsSection.vue"
              ),
          },
          {
            path: "otros",
            name: "OthersSection",
            component: () =>
              import(
                /*webpackChunkName: "viewOthersFile"*/ "../views/OthersSection.vue"
              ),
          },
        ],
      },
    ],
  },
];
